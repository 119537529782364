import React, { useState, useEffect } from "react";
import "./styles.css";

const emojis = ["🍎", "🍋", "🍒", "🍇", "🍊", "🍉", "💎", "🌟"];

const winConditions = {
  "💎💎💎": 1000,
  "🌟🌟🌟": 500,
  "🍒🍒🍒": 300,
  "🍇🍇🍇": 200,
  "🍊🍊🍊": 150,
  "🍎🍎🍎": 100,
  "🍋🍋🍋": 80,
  "🍉🍉🍉": 60,
};

function App() {
  const [slots, setSlots] = useState(["🎰", "🎰", "🎰"]);
  const [coins, setCoins] = useState(100);
  const [betAmount, setBetAmount] = useState(10);
  const [spinning, setSpinning] = useState(false);
  const [message, setMessage] = useState("");

  const spinSlots = () => {
    if (coins < betAmount) {
      setMessage("Not enough coins!");
      return;
    }
    setCoins(coins - betAmount);
    setSpinning(true);
    setMessage("");

    let spins = 0;
    const maxSpins = 20;
    const interval = setInterval(() => {
      setSlots(
        slots.map(() => emojis[Math.floor(Math.random() * emojis.length)])
      );
      spins++;
      if (spins >= maxSpins) {
        clearInterval(interval);
        setSpinning(false);
        checkWin();
      }
    }, 100);
  };

  const checkWin = () => {
    const result = slots.join("");
    let winAmount = 0;

    for (const [combination, prize] of Object.entries(winConditions)) {
      if (result === combination) {
        winAmount = prize * (betAmount / 10);
        break;
      }
    }

    if (winAmount > 0) {
      setCoins((coins) => coins + winAmount);
      setMessage(`You won ${winAmount} coins!`);
    } else {
      setMessage("Try again!");
    }
  };

  const resetGame = () => {
    setSlots(["🎰", "🎰", "🎰"]);
    setCoins(100);
    setMessage("");
    setBetAmount(10);
  };

  return (
    <div className="slot-machine">
      <h1 className="title">Emoji Slots! 🎰</h1>
      <div className="slot-display">
        {slots.map((slot, index) => (
          <div key={index} className={`slot ${spinning ? "spinning" : ""}`}>
            {slot}
          </div>
        ))}
      </div>
      <div className="controls">
        <div className="coin-display">🪙 {coins}</div>
        <div className="bet-control">
          <button onClick={() => setBetAmount(Math.max(10, betAmount - 10))}>
            -
          </button>
          <span>{betAmount}</span>
          <button onClick={() => setBetAmount(Math.min(100, betAmount + 10))}>
            +
          </button>
        </div>
      </div>
      <button onClick={spinSlots} className="spin-button" disabled={spinning}>
        {spinning ? "Spinning..." : "Spin"}
      </button>
      {message && <div className="message">{message}</div>}
      <button onClick={resetGame} className="reset-button">
        Reset Game
      </button>
      <h2>Win Conditions</h2>
      <div className="win-conditions">
        {Object.entries(winConditions).map(([combo, prize]) => (
          <div key={combo} className="win-condition">
            <span>{combo}</span>
            <span>{prize}x bet</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
